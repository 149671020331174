import React, { useState, useEffect } from "react";
import { Twitter, Coffee, Share2 } from "lucide-react";

const App = () => {
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [error, setError] = useState(null);

  const handleBuyCoffee = async () => {
    try {
      const response = await fetch("https://syncx.scanx.cc/create-order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Custom-Auth-Key": "your-auth-key-here",
        },
        body: JSON.stringify({ amount: 5 }), // $5 for coffee
      });

      if (!response.ok) {
        throw new Error("Failed to create order");
      }

      const data = await response.json();
      setOrderId(data.data.prepayId);
      window.open(data.data.checkoutUrl, "_blank");
    } catch (err) {
      setError("Failed to initiate payment. Please try again.");
      console.error("Error:", err);
    }
  };

  const checkPaymentStatus = async () => {
    if (!orderId) return;

    try {
      const response = await fetch("https://syncx.scanx.cc/verify-payment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Custom-Auth-Key": "your-auth-key-here",
        },
        body: JSON.stringify({ orderId }),
      });

      if (!response.ok) {
        throw new Error("Failed to verify payment");
      }

      const data = await response.json();
      setPaymentStatus(data.status);
      if (data.status === "success") {
        clearInterval(window.paymentCheckInterval);
      }
    } catch (err) {
      setError("Failed to verify payment status. Please contact support.");
      console.error("Error:", err);
    }
  };

  useEffect(() => {
    if (orderId) {
      window.paymentCheckInterval = setInterval(checkPaymentStatus, 5000); // Check every 5 seconds
      return () => clearInterval(window.paymentCheckInterval);
    }
  }, [orderId]);

  return (
    <div className="min-h-screen bg-white text-gray-800 font-sans">
      <header className="bg-white border-b border-gray-200 fixed w-full z-10">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <div className="text-2xl font-bold text-blue-500">SyncX</div>
          <nav>
            <ul className="flex space-x-6">
              <li>
                <a href="#features" className="hover:text-blue-500 transition-colors">
                  Features
                </a>
              </li>
              <li>
                <a href="#usage" className="hover:text-blue-500 transition-colors">
                  How to Use
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      <main>
        <section className="min-h-screen flex items-center bg-gradient-to-r from-blue-500 to-purple-600 text-white">
          <div className="container mx-auto px-4 flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 mb-8 md:mb-0">
              <h1 className="text-5xl font-bold mb-6">Get start with SyncX</h1>
              <p className="text-xl mb-8">
                With SyncX installed, start enjoying the convenience of sharing your Twitter content across multiple social platforms, all in one place.
              </p>
              <a
                href="https://chrome.google.com/webstore"
                className="bg-white text-blue-500 px-8 py-3 rounded-full text-lg font-semibold hover:bg-gray-100 transition-colors inline-block"
              >
                Add to Chrome
              </a>
            </div>
            <div className="md:w-1/2">
              <div className="bg-white rounded-lg shadow-2xl p-4">
                <img src="/cover.png" alt="SyncX Dashboard" className="rounded" />
              </div>
            </div>
          </div>
        </section>

        <section id="usage" className="min-h-screen flex items-center bg-gray-50">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-bold mb-12 text-center">How to Use SyncX</h2>
            <div className="grid md:grid-cols-2 gap-8">
              <div className="bg-white rounded-lg shadow-xl p-8">
                <div className="flex items-start mb-6">
                  <Twitter className="text-blue-500 mr-4 flex-shrink-0" size={32} />
                  <div>
                    <h3 className="text-2xl font-semibold mb-2">Free</h3>
                    <p className="text-gray-600 mb-4">Follow SyncX on Twitter and share one of our tweets to get free access!</p>
                    <ul className="list-disc list-inside mb-6 text-gray-600">
                      <li>Share to Warpcast</li>
                      <li>Share to Hey</li>
                    </ul>
                    <a
                      href="https://twitter.com/syncx"
                      className="inline-flex items-center bg-blue-500 text-white px-6 py-2 rounded-full hover:bg-purple-700 transition-colors"
                    >
                      <Share2 className="mr-2" size={20} />
                      Follow and Share
                    </a>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-lg shadow-xl p-8">
                <div className="flex items-start mb-6">
                  <Coffee className="text-purple-500 mr-4 flex-shrink-0" size={32} />
                  <div>
                    <h3 className="text-2xl font-semibold mb-2">Support</h3>
                    <p className="text-gray-600 mb-4">Support our development by buying us a Starbucks coffee ($5)!</p>
                    <ul className="list-disc list-inside mb-6 text-gray-600">
                      <li>Without Follow and Retweet</li>
                    </ul>
                    <button
                      onClick={handleBuyCoffee}
                      className="inline-flex items-center bg-purple-600 text-white px-6 py-2 rounded-full hover:bg-purple-700 transition-colors"
                      disabled={paymentStatus === "processing"}
                    >
                      <Coffee className="mr-2" size={20} />
                      {paymentStatus === "processing" ? "Processing..." : "Buy Coffee ($5)"}
                    </button>
                    {error && <p className="text-red-500 mt-2">{error}</p>}
                    {paymentStatus === "success" && <p className="text-green-500 mt-2">Thank you for your support!</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer className="bg-gray-800 text-white py-6">
        <div className="container mx-auto px-4">
          <div className="text-center text-sm">
            <p>&copy; 2024 ScanX. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default App;